<!--
 * @Author: tongteng
 * @Date: 2023-02-27 15:51:04
 * @LastEditors: tongteng
 * @LastEditTime: 2023-04-23 14:11:53
 * @Description: 供应商线索上传页面
-->
<template>
  <div>
    <!-- nav bar -->
    <nav-bar v-if="showBackBtn" @onBack="handleBack" />
    <div v-if="isClose">
      <div v-if="validateParams" class="supplierTask">
        <h3>{{ needObscure ? OBSCURE_NAME : skuTaskName }}</h3>
        <div>{{ effectiveTime }}</div>
        <div class="supplierTaskUpload">
          <div class="supplierTaskUploadFile">
            <div class="process">
              <p class="process-title">完成进度</p>
              <van-progress :pivot-text="processText" stroke-width="10" :percentage="process" />
            </div>
            <div class="supplier-info">
              <div class="supplier-info-row">
                <span>品牌：</span><span class="row-text">{{ brand }}</span>
              </div>
              <div class="supplier-info-row">
                <span>类目：</span><span class="row-text">{{ lastClassCategory }}</span>
              </div>
              <div class="supplier-info-row">
                <span>关键词：</span><span class="row-text">{{ searchKeyword }}</span>
                <van-button v-bind="btnConfig" @click="$utils.copyText(searchKeyword)"
                  >复制</van-button
                >
              </div>
            </div>
            <div class="line gap-top"></div>
            <div class="gap-top">
              <p class="upload-tips">请上传图片，最大不超过10M</p>
              <image-collection
                v-if="uploadOptions"
                ref="skuImg"
                :uploadOptions="uploadOptions"
                :accept="accept"
                :task-id="urlParams.userTaskId"
                :job-id="urlParams.jobId"
                :check-repeat="true"
                :is-show-add-btn="true"
                :is-check-time="true"
                :job-start-time="jobStartTime"
                :max-image-size="maxImageSize"
                btn-size="small"
                @file-change="onFileChange"
              />
              <div v-else>{{ uploadTips }}</div>
            </div>
          </div>
        </div>
        <div class="submit">
          <van-button
            class="supplierTaskSubmit"
            :loading="loading"
            loading-text="提交中..."
            @click="handleSubmit"
          >
            点我提交!
          </van-button>
          <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
        </div>
        <van-dialog
          title="【请再次确认以下信息是否正确】"
          v-model="isShowConfirm"
          confirm-button-text="确认提交"
          cancel-button-text="我再想想"
          :show-cancel-button="true"
          @confirm="confirmSubmit"
          @cancel="cancelSubmit"
        >
          <div class="confirm-content">
            <p>{{companyName}}</p>
            <p>{{deliveryArea}}</p>
            <p>{{searchKeyword}}</p>
          </div>
        </van-dialog>
      </div>
      <paramsError v-else />
    </div>
    <van-empty v-else description />
  </div>
</template>

<script>
import imageCollection from "../ImageCollection/Index";
import NavBar from "../components/nav-bar.vue";
import paramsError from '../components/paramsError.vue'
import {
  getTaskDetails,
} from "@/apis";
import { formatDate } from "@/tools/date";
import { checkUploadImages } from "../shared";
import { Toast } from "vant";
import submitFileMixins from '../mixins/submit-file'
import backListMixins from "../mixins/back-list";

const OBSCURE_KEYWORD = "脱敏";

export default {
  name: "SkuTask",
  components: {
    imageCollection,
    NavBar,
    paramsError,
  },
  mixins: [backListMixins, submitFileMixins],
  data() {
    return {
      skuTaskName: "",
      effectiveTime: "",
      loading: false,
      isClose: true,
      accept: "image/*",
      taskStartTime: 0,
      taskEndTime: 0,
      note: "商品列表长截图（拍照/其他页面无效）",
      title: "",
      OBSCURE_NAME: "录屏事件名称",
      needObscure: false,
      recordAppFlag: 1,
      uploadOptions: null,
      uploadTips: "",
      taskType: "",
      brand: "",
      lastClassCategory: "",
      searchKeyword: "",
      qualifiedNumber: 0,
      needNumber: 0,
      jobStartTime: 0,
      deliveryArea: "",
      companyName: "",
      isShowConfirm: false,
      submitInfo: null,
      submitImgs: null,
      userCode: '',
      maxImageSize: 1024 * 1024 * 10,
      btnConfig: {
        plain: true,
        hairline: true,
        color: "#1989fa",
        size: "mini",
        type: "primary",
      },
    };
  },

  destroyed() {
    document.title = "";
  },

  computed: {
    processText() {
      return `${this.qualifiedNumber || 0}/${this.needNumber || 0}`
    },
    process() {
      if (this.needNumber === 0) {
        return 0;
      } else if(this.qualifiedNumber > this.needNumber) {
        return 100;
      } else {
        return this.qualifiedNumber / this.needNumber * 100;
      }
    }
  },

  methods: {
    /**
     * @description: 弹窗确定提交
     * @return {*}
     */    
    confirmSubmit() {
      this.fetchTaskNew(this.submitInfo, this.submitImgs, 'supplierLeads')
    },
    /**
     * @description: 弹窗取消提交
     * @return {*}
     */    
    cancelSubmit() {
      this.loading = false
    },
    /**
     * @description: 获取当前任务详情
     * @return {*}
     */    
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams;
        const res = await getTaskDetails({ userTaskId, status: status ? +status : undefined });
        if (res) {
          const data = res.data || {};
          if (res.code !== 0)
            return Toast.fail(res.msg || "获取任务详情接口返回错误");
          const time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          );
          if (data.note) {
            this.note = data.note;
          }
          this.needObscure = !!data.isSensitive;
          this.skuTaskName = data.name || "";
          if (data.name) {
            if (data?.name?.indexOf(OBSCURE_KEYWORD) > -1 || this.needObscure) {
              document.title = this.OBSCURE_NAME;
            } else {
              document.title = data.name;
            }
          }
          if (data.recordAppFlag === 2) {
            this.recordAppFlag = 2;
          }
          this.effectiveTime = `有效时间至:${time}`;
          this.taskType = data.taskType;
          this.brand = data.brand || "";
          this.userCode = data.userCode || ''
          this.lastClassCategory = data.lastClassCategory || "";
          this.searchKeyword = data.searchKeyword || "";
          this.qualifiedNumber = data.qualifiedNumber || 0;
          this.needNumber = data.needNumber || 0;
          this.jobStartTime = data.jobStartTime || "";
          this.deliveryArea = data.deliveryArea || "";
          this.companyName = data.companyName || "";
        }
      } catch (err) {
        Toast.fail(err.message || "获取任务详情接口请求错误");
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now();
    },
    /**
     * @description: 提交文件
     * @return {*}
     */    
    async handleSubmit() {
      if (this.loading) return;
      this.loading = true;
      try {
        const imgs = this.$refs.skuImg.getImagesList();
        const submitInfo = await checkUploadImages(imgs);
        if (submitInfo) {
          const verifyValid = await this.$refs.skuImg.checkFileValid();
          if (!verifyValid.valid) {
            Toast.fail(
              verifyValid.messageType
                ? verifyValid.message
                : `${verifyValid.message}，请重新上传后提交`
            );
            this.loading = false
            return;
          }

          this.submitInfo = submitInfo;
          this.submitImgs = imgs;
          this.$nextTick(() => {
            this.isShowConfirm = true
          })
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
    
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
};
</script>

<style lang="less" scoped>
.supplierTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .supplierTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .supplierTaskUploadFile {
      .upload-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .toolTip {
          font-size: 13px;
        }
        .auto-recording-button {
          color: #fff;
          background-color: #007aff;
          border-radius: 8px;
        }
      }
      .process {
        margin-bottom: 10px;
        .process-title {
          margin: 0 0 15px 0;
          text-align: center;
          font-size: 15px;
          font-weight: 800;
        }
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .supplierTaskSubmit {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
.supplier-info-row {
  padding: 5px 0;
  & > span {
    display: inline-block;
    vertical-align: top;
    line-height: 24px;
  }
}
.row-text {
  padding-right: 5px;
}
.line {
  width: 100%;
  height: 1px;
  background: #999;
}
.gap-top {
  margin-top: 15px;
  .upload-tips {
    color: #888;
    font-size: 12px;
    margin-left: 0.2rem;
  }
}
.img-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: #fff;
}

.districtHint{
   color: red;
   font-size: 15px;
}

.confirm-content {
  text-align: center;
  .confirm-again {
    font-weight: 500;
  }
}
</style>